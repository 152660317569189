import React from "react"
import { Link, PageProps } from "gatsby"
import { graphql } from "gatsby"
import DefaultLayout from "../../../components/layouts/DefaultLayout"
import HeroImage from "../../../components/organisms/HeroImage"
import { HelmetDatoCmsTags } from "gatsby-source-datocms"
import { IGatsbyImageData } from "gatsby-plugin-image"

type DataProps = {
  datoCmsSite: {
    globalSeo: {
      fallbackSeo: {
        title: string
        description: string
        image: {
          gatsbyImageData?: IGatsbyImageData
        }
      }
      siteName: string
      titleSuffix: string
      twitterAccount: string
    }
  }
}

const Page = ({ data }: PageProps<DataProps>) => {
  const globalSeo = data.datoCmsSite.globalSeo

  const seoMetaTags: HelmetDatoCmsTags = {
    tags: [
      {
        tagName: "title",
        content: `404 - Porin YH-Asunnot Oy`,
      },
      {
        tagName: "description",
        content: "Page not found",
      },
    ],
  }

  const seo = globalSeo.fallbackSeo

  return (
    <DefaultLayout seoMetaTags={seoMetaTags}>
      <HeroImage image={seo.image} />
      <h1>404 - {seo.title ? seo.title : "Page not found"}</h1>
      <p>
        Return to <Link to="/en">frontpage</Link>
      </p>
    </DefaultLayout>
  )
}

export default Page

export const query = graphql`
  query NotFoundPageEn {
    datoCmsSite {
      globalSeo {
        fallbackSeo {
          title
          description
          image {
            #fluid(imgixParams: { fit: "crop", w: "2000", h: "500" }, maxWidth: 2000) {
            #  ...GatsbyDatoCmsFluid
            #}
            gatsbyImageData(layout: FULL_WIDTH, width: 2000, height: 500)
          }
        }
        siteName
        titleSuffix
        twitterAccount
      }
    }
  }
`
